import React, { createContext, useState, useEffect, Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import { __ } from './providers/Language';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Dashboard from "./views/Dashboard";

import {
  Box,
} from '@mui/material';

const WebsiteNew = lazy(() => import("./views/Website/New"));
const WebsiteSettings = lazy(() => import("./views/Website/Settings"));
const WebsiteCloudflare = lazy(() => import("./views/Website/Cloudflare"));
const WebsitePayment = lazy(() => import("./views/Website/Payment"));
const Account = lazy(() => import("./views/Account/View"));
const PaymentHistory = lazy(() => import("./views/Account/PaymentHistory"));
const Login = lazy(() => import("./views/Login"));

export const AuthContext = createContext({})

const App = () => {
  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem('auth');

    try {
      return storedAuth ? JSON.parse(storedAuth) : null;
    } catch (e) {
      return null;
    }
  });

  useEffect(() => {
    if (!auth)
      localStorage.removeItem('auth');
  }, [auth]);

  return (
    <>
      {
        auth ? (
          <Router>
            <AuthContext.Provider value={{ auth, setAuth }}>
              <Suspense fallback={
                <Box sx={{ p: 4 }}>{__('loading')}</Box>
              }>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/account/settings" element={<Account />} />
                  <Route path="/account/payment-history" element={<PaymentHistory />} />

                  <Route path="/website/create" element={<WebsiteNew />} />
                  <Route path="/website/:id/cloudflare" element={<WebsiteCloudflare />} />
                  <Route path="/website/:id/:tab?" element={<WebsiteSettings />} />
                  <Route path="/website/:id/payment" element={<WebsitePayment />} />

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Suspense>
            </AuthContext.Provider>
          </Router>
        ) : (
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        )
      }
      <ToastContainer />
    </>
  );
};

export default App;
