import { toast } from 'react-toastify';

class ZoneModel {

  static zones = {
    url: 'zones',
    method: 'get',
  }

  static add = {
    url: 'zones/add',
    method: 'post',
    error: (response, status) => {
      if (status === 400)
        toast.error(response.message, {
          position: 'bottom-left',
          theme: 'colored',
          closeButton: false
        })
    },
  }

  static check = {
    url: 'zones/check',
    method: 'post',
  }

}

export default ZoneModel;
