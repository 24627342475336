import React from 'react';

import tr from '../lang/tr.json';
import en from '../lang/en.json';

const languages = { tr, en };

const getBrowserLanguage = () => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  return browserLanguage.substr(0, 2);
};

const __ = (key, params) => {
  const selectedLanguage = languages[getBrowserLanguage()] || en;

  let translation = selectedLanguage[key] ? selectedLanguage[key] : key;

  if (params) {
    for (let paramKey in params) {
      const regex = new RegExp(`:${paramKey}`, 'g');
      translation = translation.replace(regex, params[paramKey]);
    }
  }

  return /<[a-z][\s\S]*>/i.test(translation) ? <span dangerouslySetInnerHTML={{ __html: translation }} /> : translation;
};

export { __, getBrowserLanguage };
