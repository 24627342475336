const calculateDaysLeft = (date) => {
  var today = new Date();
  var expiryDate = new Date(date);
  var difference = expiryDate.getTime() - today.getTime();
  var daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));

  return daysLeft;
}

export { calculateDaysLeft };
