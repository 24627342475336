import React from 'react';
import {
  Button,
  Typography,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import { __ } from '../providers/Language';

function CustomButton(
  {
    label,
    variant,
    textVariant,
    loadingButton,
    loading,
    ...other
  }
) {
  const content = <Typography variant={textVariant ?? 'body2'}>{__(label)}</Typography>;

  return (
    loadingButton ? (
      <LoadingButton variant={variant ?? 'contained'} loading={loading} {...other}>
        {content}
      </LoadingButton>
    ) : (
      <Button variant={variant ?? 'contained'} {...other}>
        {content}
      </Button>
    )
  );
}

export default CustomButton;
