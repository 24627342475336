import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Theme from '../providers/Theme';
import Auth from '../providers/Auth';
import Api from '../providers/Api';

import TinyFooter from '../components/TinyFooter';

import AuthModel from '../models/AuthModel';

import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Container,
  List,
  ListItem,
  ListItemButton,
  Drawer,
  Chip,
  LinearProgress,
} from '@mui/material';

import {
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';

import { __ } from '../providers/Language';

import '../scss/layout.scss';

const logOut = async (e) => {
  const formData = new FormData();
        formData.append('bearer', Auth.getLocalStorage()?.access_token)

  Api.send(
    {
      service: AuthModel.logout,
      formData: formData,
      completed: () => {
        Auth.clearLocalStorage();

        window.location.href = '/';
      },
    }
  )
}

function Layout({ children, drawerItems = [], splash = true }) {
  const [anchorEl, setAnchorEl] = useState(false);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(false);

  const topBarMenu = (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}>
      <MenuItem component={Link} to="/account/settings">
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {__('account_settings')}
            <Typography variant="caption" color="text.secondary">{Auth.getLocalStorage()?.email}</Typography>
          </Box>
        </ListItemText>
      </MenuItem>
      <MenuItem component={Link} to="/account/payment-history">
        <ListItemIcon>
          <AccountBalanceWalletIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{__('payment_history')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={logOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{__('logout')}</ListItemText>
      </MenuItem>
    </Menu>
  );

  const drawerWidth = drawerItems.length ? 300 : 0;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleDrawerClose = () => setMobileOpen(false);

  const drawer = <List>
    {
      drawerItems.map((item) => (
        item.route ? <ListItem key={item.label} disablePadding>
          <ListItemButton component={Link} to={item.route} onClick={handleDrawerClose}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem> : <Chip key={item.label} sx={{ bgcolor: 'transparent', opacity: .5 }} label={item.label} />
      ))
    }
  </List>;

  const drawerWrapper = (
    <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
      <Drawer
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' }, '& .MuiDrawer-paper': { width: drawerWidth } }}>
        {drawer}
      </Drawer>
      <Drawer variant="permanent" sx={{
        display: {
          xs: 'none',
          md: 'block'
        },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          borderWidth: 0,
          top: '0px',
          bottom: '0px'
        }
      }} open>
        {drawer}
      </Drawer>
    </Box>
  );

  return (
    <Theme>
      <Box sx={{
        position: 'fixed',
        display: splash ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        inset: 0,
        zIndex: 999999999,
        bgcolor: 'background.paper',
      }}>
        <img alt="Etsetra" src={'../../images/icon.svg'} width={64} height={64} />
        <LinearProgress sx={{ width: '100px' }} color="primary" />
      </Box>

      {!splash && <>
        <CssBaseline />

        <AppBar position="fixed" sx={{ pl: { sm: 0, md: `${drawerWidth}px` } }}>
          <Toolbar>
            {
              drawerItems.length ? <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ display: { md: 'none' } }}>
                <MenuIcon />
              </IconButton> : ''
            }
            <Link to="/">
              <img style={{ marginTop: '7px' }} alt="Logo" src="/images/icon-white.svg" height={32} />
              <Typography color="white" component="span" variant="caption">αlpha</Typography>
            </Link>
            <Box sx={{ flexGrow: 1 }} />

            <IconButton size="large" onClick={handleMenu} color="inherit">
              <AccountCircleIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box component="main" sx={{ py: 2, pl: { md: `${drawerWidth}px` } }}>
          <Toolbar />

          <Container maxWidth="lg">
            {children}

            <TinyFooter sx={{ mt: 4 }} />
          </Container>
        </Box>

        {drawerWrapper}
        {topBarMenu}
      </>}
    </Theme>
  );
}

export default Layout;
