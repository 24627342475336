import { toast } from 'react-toastify';

import Auth from './Auth';
import { __, getBrowserLanguage } from '../providers/Language';

class Api {
  static arrayToQueryString = (arr) => {
    return arr.map(obj => {
      return Object.keys(obj).map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
      }).join('&');
    }).join('&');
  }

  static headers = () => {
    const headers = new Headers();
          headers.append('Accept', 'application/json');
          headers.append('Accept-Language', getBrowserLanguage());

    if (Auth.getLocalStorage()?.access_token)
      headers.append('Authorization', 'Bearer ' + Auth.getLocalStorage().access_token)

    return headers;
  }

  static base = process.env.REACT_APP_API_URL;

  static async send({ service, formData, success, error, completed, query, params, download }) {
    let url = `${this.base}/${service.url}`;

    if (params?.length)
      url += '?' + this.arrayToQueryString(params);

    if (typeof query === 'object')
      Object.entries(query).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value)
      })

    try {
      const response = await fetch(url, {
        method: service.method,
        headers: this.headers(),
        body: formData,
      });

      if (download)
      {
        if (typeof service.success === 'function')
          service.success(response.url);

        if (typeof success === 'function')
          success(response.url);

        if (typeof completed === 'function')
          completed()
      }
      else
      {
        const json = await response.json();

        if (response.ok) {
          if (typeof service.success === 'function')
            service.success(json);

          if (typeof success === 'function')
            success(json);

          if (typeof completed === 'function')
            completed()
        } else {
          this.error(json, response.status)

          if (typeof service.error === 'function')
            service.error(json, response.status)

          if (typeof error === 'function')
            error(json, response.status)

          if (typeof completed === 'function')
            completed()
        }
      }
    } catch (err) {
      toast.error(__('network_problem'), {
        position: 'bottom-right',
        theme: 'colored',
        closeButton: true
      })

      if (typeof error === 'function') {
        error({ message: 'Network error' }, 0);
      }
      if (typeof completed === 'function') {
        completed();
      }
    }
  }

  static async error(response, status) {
    switch (status) {
      case 401:
        Auth.clearLocalStorage()
        window.location.href = '/';
        break;
      case 429:
        toast.error(response.message, {
          position: 'bottom-right',
          theme: 'colored',
          closeButton: true
        })
        break;
      case 422:
        //
        break;
      default:
        //
        break;
    }
  }
}

export default Api;
