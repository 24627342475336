import { toast } from 'react-toastify';

class AuthModel {

  static generateToken = {
    url: 'generate-token',
    method: 'post',
    success: (response) => {
      toast.success(response.message, {
        position: 'bottom-left',
        theme: 'colored',
        closeButton: false
      })
    },
  }

  static login = {
    url: 'auth/login',
    method: 'post'
  }

  static logout = {
    url: 'auth/logout',
    method: 'post'
  }

  static authorizations = {
    url: 'auth/authorizations',
    method: 'get'
  }

}

export default AuthModel;
