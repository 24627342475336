import {
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';

function CustomCard({ title, children, sx, titleProps, before, after, actions, ...other }) {
  return (
    <Card sx={{ position: 'relative', ...sx }} elevation={2} {...other}>
      { before ?? '' }
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="h6" {...titleProps}>
            {title}
          </Typography>
          {
            actions ? (
              <Box>
                {actions}
              </Box>
            ) : ''
          }
        </Box>
        <>
          {children}
        </>
      </CardContent>
      { after ?? '' }
    </Card>
  );
}

export default CustomCard;
