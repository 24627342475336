class Auth {
  static getLocalStorage()
  {
    let auth = localStorage.getItem('auth');

    try
    {
      auth = JSON.parse(auth) ?? {};
    }
    catch
    {
      localStorage.removeItem('auth')

      auth = {};
    }

    return auth;
  }

  static setLocalStorage(response)
  {
    try
    {
      localStorage.setItem('auth', JSON.stringify(response))
    }
    catch (e)
    {
      return {};
    }

    return this.getLocalStorage()
  }

  static clearLocalStorage()
  {
    localStorage.removeItem('auth')
  }

  static patchLocalStorage(key, value) {
    const authData = this.getLocalStorage();

    authData[key] = value;

    this.setLocalStorage(authData);
  }
}

export default Auth;
