import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Badge,
  Box,
  styled,
  Stack,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';

import {
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
  AddCircle as AddCircleIcon,
} from '@mui/icons-material';

import { __ } from '../providers/Language';
import Api from '../providers/Api';

import ZoneModel from '../models/ZoneModel';

import Layout from '../components/Layout';
import CustomCard from '../components/CustomCard';
import Notfound from '../components/Notfound';
import CustomButton from '../components/CustomButton';

import { blueGrey } from '@mui/material/colors';

import { calculateDaysLeft } from '../helpers/DateHelper';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

function Dashboard() {
  const [data, setData] = useState([]);
  const [dataNextCursor, setDataNextCursor] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [search, setSearch] = useState('');
  const [searchTimeout] = useState(null);
  const searchRef = useRef(null);

  const handleSearchChange = (e) => {
    if (searchRef.current)
      clearTimeout(searchRef.current);

    searchRef.current = setTimeout(() => setSearch(e.target.value), 600);
  };

  useEffect(() => {
    return () => clearTimeout(searchTimeout);
  }, [searchTimeout]);

  useEffect(() => {
    setData([]);
    setDataNextCursor(null);
    setDataLoaded(false);
  }, [search]);

  useEffect(() => {
    if (!dataLoaded) {
      const params = [];

      if (dataNextCursor)
        params.push({ cursor: dataNextCursor });

      if (search)
        params.push({ search: search });

      Api.send({
        service: ZoneModel.zones,
        params: params,
        success: (response) => {
          setData((prevData) => [...prevData, ...response.data]);
          setDataNextCursor(response.next_cursor);
        },
        completed: () => setDataLoaded(true),
      });
    }
  }, [dataNextCursor, dataLoaded, search]);

  useEffect(() => {
    document.title = `Etsetra`;
  })

  return (
    <Layout splash={false}>
      <Box
        sx={{
          mb: 2,
          gap: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
        }}>
        <CustomButton
          component={Link}
          to="/website/create"
          startIcon={<AddCircleIcon />}
          size="large"
          label="new_website" />
      </Box>
      <CustomCard
        title={__('zones')}
        actions={
          <TextField sx={{ width: 148 }} onChange={handleSearchChange} label={__('search')} variant="outlined" size="small" />
        }>
        <List sx={{ p: 0 }}>
          {
            data.map((item) => (
              <ListItem key={item.id} sx={{ gap: 1, '&:hover': { bgcolor: blueGrey[50] } }}>
                <ListItemText
                  primary={
                    <Typography
                      component={Link}
                      to={`https://www.${item.name}`}
                      target="_blank"
                      rel="noopener"
                      variant="subtitle1"
                    >
                      {item.name}
                    </Typography>
                  }
                  secondary={moment(item.site_expiry_at).format('DD/MM/YYYY')} />
                <Link to={`/website/${item.id}/cloudflare`}>
                  <Badge badgeContent={item.approved_at ? 0 : 1} variant="dot" color="error">
                    <img alt="Cloudflare" src="/images/cloudflare-logo.svg" width={64} />
                  </Badge>
                </Link>
                <IconButton component={Link} to={`/website/${item.id}/payment`}>
                  <Badge badgeContent={calculateDaysLeft(item.site_expiry_at) < 6 ? 1 : 0} variant="dot" color="error">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
                <IconButton component={Link} to={`/website/${item.id}/`}>
                  <StyledBadge badgeContent={item.unread_messages + item.not_reviewed_applications} color="error">
                    <SettingsIcon />
                  </StyledBadge>
                </IconButton>
              </ListItem>
            ))
          }
          {
            !dataLoaded && !data.length && (
              <ListItem key="loading">
                <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
                  <Stack sx={{ flexGrow: 1 }}>
                    <Skeleton variant="text" sx={{ width: '140px' }} />
                    <Skeleton variant="text" sx={{ width: '100px' }} />
                  </Stack>
                  <Skeleton variant="rounded" width={48} height={32} />
                  <Skeleton variant="circular" width={32} height={32} />
                  <Skeleton variant="circular" width={32} height={32} />
                </Stack>
              </ListItem>
            )
          }
          {
            data.length || dataLoaded === false ? (
              ''
            ) : (
              <ListItem key="notfound">
                <Notfound message={__('website_notfound')} />
              </ListItem>
            )
          }
        </List>
        {
          dataNextCursor && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CustomButton
                onClick={() => setDataLoaded(false)}
                label="more"
                size="small"
                variant="text"
                loadingButton={true}
                loading={!dataLoaded}
              />
            </Box>
          )
        }
      </CustomCard>
    </Layout>
  );
}

export default Dashboard;
