import {
    Stack,
    Typography,
} from '@mui/material';

import { blueGrey } from '@mui/material/colors';

import { __ } from '../providers/Language';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';

function Notfound({ message, sx, ...other }) {
  return (
    <Stack sx={{ width: '100%', display: 'flex', alignItems: 'center', ...sx }} spacing={2} {...other}>
      <ManageSearchIcon sx={{ fontSize: '48px', color: blueGrey[600] }} />
      <Typography variant="body2" sx={{ color: blueGrey[600], maxWidth: '400px', textAlign: 'center' }}>{message ?? __('notfound')}</Typography>
    </Stack>
  );
}

export default Notfound;
