import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { __ } from '../providers/Language';

import { blueGrey } from '@mui/material/colors';

function TinyFooter({ sx }) {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      mb: 5,
      gap: .5,
      textAlign: 'center',
      ...sx
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 1 }}>
        <Link to={`${process.env.REACT_APP_HOME_URL}/faq`}>
          <Typography variant="caption" sx={{ color: blueGrey[900] }}>{__('faqs')}</Typography>
        </Link>
        <Link to={`${process.env.REACT_APP_HOME_URL}/contact`}>
          <Typography variant="caption" sx={{ color: blueGrey[900] }}>{__('contact')}</Typography>
        </Link>
        <Link to={`${process.env.REACT_APP_HOME_URL}/legal`}>
          <Typography variant="caption" sx={{ color: blueGrey[900] }}>{__('legal')}</Typography>
        </Link>
      </Box>
      <img alt="Logo" src="/images/logo-grey.svg" height={32} />
      <Typography variant="caption" color="text.secondary">Powered by Etsetra Ltd. <br />{__('all_rights_reserved')}</Typography>
    </Box>
  );
}

export default TinyFooter;
